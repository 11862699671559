.header_button {
    border: 2px solid transparent;
    border-radius: 4em;
    background-color: var(--button-background-color);
    color: var(--button-font-color);
    padding: 20px 20px;
    font-weight: bolder;
    cursor: pointer;
}

.header_button:hover {
    opacity: 0.7;
}

.modal_close_button {
    float: right;
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
    color: var(--button-font-color);
    background-color: transparent !important;
}

.modal_title {
    text-align: center;
    font-weight: bold;
    color: var(--button-font-color);
    margin: 20px;
}

.modal_form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.modal_form div {
    text-align: center;
}

.modal_form_container {
    margin: 20px;
}

.form_input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.date_time_picker_container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.p_error {
    padding-top: 3px;
    text-align: left;
    color: var(--danger-color);
}

.date_time_picker_label {
    display: flex;
    padding: 7px;
    font-weight: bold;
    color: var(--main-description-text-color);
}

.date_time_picker {
    overflow-x: auto;  /* Enable horizontal scrolling if content overflows */
    max-width: 100%;   /* Ensure it doesn't exceed the parent's width */
  }

.form_input label, .date_time_picker label {
    text-align: left !important;
    padding: 7px;
    font-weight: bold;
    color: var(--circle-background-color);
}

.form_input label::after, .date_time_picker label::after, .date_time_picker_label label::after {
    content: ' *';
    color: var(--danger-color);
}

.form_input input {
    height: 50px;
    border-radius: 4px;
    border: 1px solid #ccc !important;
    padding: 10px;
    width: 100%;
}

.date_time_picker {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.date_time_picker input {
    border: none !important;
}

form div {
    width: auto !important;
}

.form_button {
    display: flex;
    margin-top: 40px;
    /* align-items: center; */
    justify-content: flex-end;
}

.snackbar_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 460px) {
    .date_time_picker_container {
        flex-wrap: wrap;
    }
}