.table_container {
    display: flex;
    flex-wrap: wrap;
    margin: 30px;
}

.table {
    display: flex !important;
    justify-content: space-between;
    margin-top: 30px;
}

.tableStyle {
    margin: 4px !important;
    width: 200px;
}

table {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.termsAndCondition {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    font-weight: bold;
}

.serviceDescription {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.serviceTitle {
    display: flex !important;
    justify-content: space-between;
}

.serviceImage {
    width: 100%;
    height: 250px;
}

.pageTitle {
    font-weight: bold;
    text-align: center;
    margin: 30px;
    text-transform: uppercase;
}

@media (max-width: 450px) {
    .table {
        flex-wrap: wrap;
    }
}