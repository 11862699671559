.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.info_container {
    width: 70%;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 30px 30px 30px 100px;
    /* border-right: 1px solid grey; */
}

.form_container {
    width: 30%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    padding: 30px 100px 10px 30px;
}

.info_container__image img {
    width: 100%;
    max-height: 350px;
}

.info_container__title {
    padding: 30px 0px 10px 0px;
    color: var(--main-description-text-color);
    font-weight: bold;
}

.info_container__card {
    margin-top: 10px;
    padding: 20px;
    border-radius: 10px;
    background-image: linear-gradient(to right, #F3F8FF, #F0F4FF);
    color: var(--button-text-color);
    text-align: left;
    font-weight: 500;
}

.info_container__description {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
}

.info_container__action {
    margin-top: 30px;
}


@media (max-width: 400px) {

    .info_container, .form_container {
        padding: 10px;
    }
}


@media (max-width: 800px) {
    .info_container, .form_container {
        width: 100%;
        padding: 40px;
    }
}


@media (min-width: 801px) and (max-width: 1024px) {
    .info_container {
        width: 60%;
    }
    
    .form_container {
        width: 40%;
    }
}