.form_container {
    display: flex;
    flex-direction: column;
}

.information_container{ 
    display: flex;
    flex-direction: column;
    min-height: 200px;
}

.information_container > div {
    margin: 10px;
    font-weight: bold;
    color: var(--main-description-text-color);

}

.information_container__contact_detail div span {
    padding: 5px;
    text-align: left;
    margin: 2px;
    color: var(--secondary-background-color);
}

.information_container__contact {
    display: flex;
    flex-direction: column;
    font-weight: normal !important;
}

.information_container__contact_detail {
    display: flex;
    flex-direction: column;
}

.information_container__contact_detail div {
    text-decoration: underline;
    margin-bottom: 4px;
}

.information_container__contact_detail div:last-child {
    pointer-events: none;
    text-decoration: none;
}

.form_input_container {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    z-index: 100;
}

.form_input_container div {
    margin: 10px;
    font-weight: bold;
    color: var(--form-title-color);
    min-width: 300px;
}

.button_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

form div {
    border: 1px solid transparent;
    margin-left: -1px !important;
}

form input {
    border: 2px solid var(--form-input-border-color) !important;
    border-radius: 5px;
}

form label {
    margin: 3px;
    font-size: 14px !important;
}

/* form label::after {
    content: ' *';
    font-weight: bold;
    color: var(--danger-color);
} */

.spinner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

button:disabled {
    cursor: not-allowed !important;
}