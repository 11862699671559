.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.full_width_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.container__image {
    max-height: 250px;
    margin: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;
}

.container__image img {
    width: 50%;
    max-height: 390px;
    margin-top: 90px;
    margin-bottom: 20px;
}

.top_info_container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 20px;
    text-align: center;
}

.container__title {
    padding: 24px 0px 10px 0px;
    color: var(--main-description-text-color);
    font-weight: bold;
}

.container__card {
    margin-top: 10px;
    padding: 30px;
    border-radius: 10px;
    background-color: var(--secondary-background-color);
    color: var(--secondary-background-color-text);
}

.container__description {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.bottom_info_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* background-color: var(--secondary-background-color-variant); */
    background-image: linear-gradient(to right, #F3F8FF, #F0F4FF);
}

.left_container {
    width: 50%;
    padding: 20px 0px 20px 40px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.left_container__title {
    color: var(--main-description-text-color);
    padding: 10px;
    font-weight: bold;
}

.left_container__description {
    padding: 10px;
    color: var(--main-description-text-color);
}

.right_container {
    width: 40%;
    padding: 20px 40px 20px 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.right_container__title {
    color: var(--main-description-text-color);
    padding: 10px;
    font-weight: bold;
}

.right_container__form {
    width: 100%;
    padding: 10px;
    color: var(--main-description-text-color);
}

.right_container__form div {
    margin: 10px;
}

.right_container__form__textArea {
    width: 100%;
    max-width: 100%;
    float: left;
}

textarea {
    width: 100%;
    border: 2px solid var(--form-input-border-color) !important;
}

.form_input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.form_input label, .right_container__form__textArea label {
    padding: 7px;
    font-weight: bold;
    color: var(--main-description-text-color);
}

.form_input input {
    height: 40px;
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 10px;
}

textarea {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 12px;
    min-width: 500px;
    margin-top: 8px;
}

.button_container {
    float: right;
    /* display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; */
}

@media (max-width: 800px) {
    .bottom_info_container {
        flex-direction: column;
        padding: 20px;
    }

    .left_container, .right_container {
        width: 100%;
        padding: 10px 20px;
    }

    .right_container__form__textArea textarea {
        min-width: 100%;
    }
    .container__image {
        margin: 0px;
    }

    .container__image img {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .top_info_container {
        padding: 16px;
        margin: 0px;
        text-align: justify;
    }
}

@media (max-width: 460px) {
    .right_container__form__textArea textarea {
        min-width: 100%;
    }

    .right_container, .left_container {
        width: 100%;
        padding: 10px;
    }
    .container__image {
        margin: 0px;
    }

    .container__image img {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .top_info_container {
        padding: 16px;
        margin: 0px;
        text-align: justify;
    }
}