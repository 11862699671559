.container {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
}

.image_container {
    display: flex;
    width: 50%;
}

.info_container {
    width: 50%;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 30px;
}

.image_container img {
    width: 100%;
}

.info_container__title {
    padding: 30px 0px 10px 0px;
    color: var(--main-description-text-color);
    font-weight: bold;
    text-align: center;
}

.info_container__card {
    margin-top: 10px;
    padding: 30px;
    border-radius: 10px;
    background-color: var(--secondary-background-color);
    color: var(--secondary-background-color-text);
    text-align: center;
}

.info_container__description {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
}

@media (max-width: 1024px) {
    .image_container, .info_container {
        width: 100%;
        margin: 0px;
    }
}