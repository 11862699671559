.servicesContainer {
    text-align: center;
    width: 100%;
    background-color: #E8E8E8;
    margin: 10px;
}

.title {
    padding: 10px;
    font-size: 36px;
}

.subtitle {
    font-size: 29px;
}

.imageContainer {
    margin: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 450px;
    overflow-y: auto;
    padding: 20px;
}

.imageContainer img {
    height: 100%;
    width: 30%;
}

.servicesDescription {
    font-size: 18px;
}

.servicesDescriptionContainer {
    margin: 30px;
    min-height: 300px;
}


@media (max-width: 500px) {
    .imageContainer img {
        width: 100%;
        height: 50%;
    } 
}