.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.info_container {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 30px 30px 30px 110px;
}

.info_container__title {
    padding: 30px 0px 10px 0px;
    color: var(--main-description-text-color);
    font-weight: bold;
}

.info_container__description {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    line-height: 1.5;
}

.contact_container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 10px 30px 30px 100px;
}

.contact_form_container {
    width: 30%;
    display: flex;
    margin-left: 15px;
}

.contact_form_container form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: -20px;
}

form div {
    width: 100%;
}

.contact_image_container {
    width: 60%;
}

.contact_image_container img {
    background-size: contain;
    width: 100%;
}

@media (max-width: 460px) {

    .contact_container, .info_container {
        padding: 10px 30px;
    }

    .contact_form_container, .contact_image_container {
        width: 100%;
        margin: 0px;
    }
}

@media (min-width: 461px) and (max-width: 800px) {
    .contact_form_container, .contact_image_container {
        width: 50%;
        margin: 0px;
    }
}