/* TODO: Update home page image.... */
.homePageInfo {
    /* min-height: 650px; */
    background-image: linear-gradient(to right, #F3F8FF, #F0F4FF);
    display: block;
    text-align: left;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    overflow-y: hidden;
    overflow-x: hidden;
    justify-content: space-between;
    padding-bottom: 20px;
}

.info_container {
    display: flex;
    flex-direction: column;
    width: 50%;
}

/* .image_container {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
} */

.homePageInfo__title {
    color: var(--circle-background-color);
    padding-left: 20px;
    margin-left: 30px;
    line-height: 1.5;
    width: 100%;
    font-weight: bold;
}

.homePageInfo__description {
    color: var(--circle-background-color);
    margin-left: 30px;
    padding-left: 20px;
    line-height: 1.5;
    width: 100%;
    font-weight: 400;
    text-align: justify;
}

.homePageInfo__button__container {
    padding-left: 20px;
    margin-left: 30px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}

.homePageServices {
    background-color: var(--secondary-background-color);
    color: var(--secondary-background-color-text);
    min-height: 300px;
    padding-bottom: 12px;
}

.homePagePricing {
    /* background-color: var(--tertiary-background-color); */
    background-image: linear-gradient(to right, #F3F8FF, #F0F4FF);
    color: var(--tertiary-background-color-text);
    min-height: 500px;
}

.homePageTestimonial {
    background-color: var(--secondary-background-color);
    color: var(--secondary-background-color-text);
    min-height: 350px;
    padding: 40px 20px;
}

.card_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 50px;
}

.homePagePricing__container {
    display: flex;
    justify-content: space-evenly;
    margin: 30px;
}

.homePageServices__title, .homePagePricing__title, .homePageTestimonial__title {
    width: 100%;
    padding: 20px;
    margin: auto;
    text-align: center;
    display: block;
    font-weight: bold;
    color: var(--circle-background-color);
}

.homePageTestimonial__title {
    color: var(--tertiary-background-color);
}

.card {
    /* border: 1px solid red; */
    background-color: white;
    color: var(--main-description-text-color);
    padding: 30px 8px;
    margin: 40px;
    width: 25%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    flex: 1 1 23%; /* Adjust to fit 4 items in a row with some spacing */
    margin: 10px;  /* Add margin if needed for spacing between cards */   
    align-items: center;
    justify-content: center;
}

.card:hover {
    /* background-color: rgb(226, 223, 223); */
    opacity: 0.9;
    box-shadow: 10px 0px 50px rgba(36, 36, 36, 0.5);
}

.card_image img {
    max-width: 160px;
    /* border-radius: 50%; */
    /* width: 100px; */
    height: 160px;
}

.card_title {
    padding: 20px;
}

.card_description {
    padding: 10px;
    min-height: 140px;
    line-height: 1.5;
}

.pricing_button_container {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card_button, .pricing_button {
    border: 2px solid transparent;
    /* border-radius: 4em; */
    background-color: var(--button-background-color);
    color: var(--button-font-color);
    padding: 12px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}

.pricing_button {
    background-color: var(--tertiary-background-color-text);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

}

.pricing_button_icon {
    margin-left: 8px;
}

.circle {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    width: 256px;
    height: 256px;
    border-radius: 50%;
    background-color: var(--circle-background-color);
    color: var(--circle-text-color);
}

.circle_head {
    padding: 5px;
    color: var(--circle-header-color);
    font-weight: bold;
}

.circle_price {
    font-weight: bold;
}


.circle_price, .circle_price_unit {
    padding: 5px;
}

a {
    text-decoration: none;
    color: var(--main-description-text-color);
}

button a {
    color: var(--button-font-color);
}


@media (max-width: 600px) {
    .homePageInfo{
        min-height: 300px;
        width: 100%;
        overflow-y: hidden;
        padding: 10px;
    }

    .homePageInfo__description {
        text-align: justify;
        margin: 0;
        padding: 0;
    }

    .homePageInfo__button__container {
        justify-content: center;
        align-items: center;
        padding-left: 0px;
        margin-left: 0px;
    }

    .info_container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    .image_container {
        display: none;
    }

    .mobileScreensOnly, .homePageInfo__description, .homePageInfo__title {
        width: 100%;
        margin: 0;
        padding: 12px;
    }

    .card_container {
        padding: 12px;
    }

    .card {
        width: 100%;
        flex: 1 1 100%;
    }

    .card_description {
        min-height: 100px;
    }

    .homePagePricing__container  {
        flex-wrap: wrap;
    }
}

@media (min-width: 700px) and (max-width: 1024px) {
    .homePageInfo{
        min-height: 300px;
        width: 100%;
        overflow-y: hidden;
        padding: 10px;
    }

    .homePageInfo__description {
        text-align: justify;
    }

    .homePageInfo__button__container {
        justify-content: center;
        align-items: center;
        padding-left: 0px;
        margin-left: 0px;
    }

    .info_container {
        width: 100%;
    }

    .image_container {
        display: none;
    }

    .homePageInfo__description, .homePageInfo__title {
        width: 100%;
        margin-left: 10px;
    }

    .homePageInfo__button {
        margin-left: 40px;
    }

    .card_container {
        padding: 12px;
    }

    .card {
        width: 30%;
        flex: 1 1 48%;
    }
}

@media (max-width: 1024px) { 
    .desktopOnly {
        display: none;
    }
}

@media (min-width: 1025px) {
    .mobileScreensOnly {
        display: none;
    }
}