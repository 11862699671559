.container {
    display: flex;
    flex-wrap: wrap;
}

.title {
    width: 100%;
    text-align: center;
    padding: 20px;
    margin-top: 20px;
    color: var(--main-description-text-color);
    font-weight: bold;
}

.dry_cleaning_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    margin: 40px;
    color: var(--pricing-card-text-color);
}

.dry_cleaning_card {
    width: 22%;
    /* background-color: var(--pricing-card-background-color); */
    background-image: linear-gradient(to right, #F3F8FF, #F0F4FF);
    border-radius: 20px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.card_icon {
    text-align: center;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.card_icon img {
    border: 2px solid var(--pricing-card-text-color);
    border-radius: 50%;
    max-height: 70px;
    min-height: 70px;
    background-color: var(--main-description-text-color);
}

.card_content {
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
}

table {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 30px;
}

thead > tr {
    margin-bottom: 8px;
}

tr {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
}

td {
    justify-content: left;
    align-items: flex-end;
}

.laundry_container {
    width: 100%;
    display: flex;
    margin: 40px;
    justify-content: space-evenly;
}

@media (min-width: 461px) and (max-width: 800px){
    .dry_cleaning_card {
        width: 45%;
        margin: 10px;
    }

    .laundry_container {
        flex-wrap: wrap;
    }
}

@media (max-width: 460px) {
    .dry_cleaning_card {
        width: 100%;
        margin: 10px;
    }

    .laundry_container {
        flex-wrap: wrap;
    }
}