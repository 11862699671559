.layoutContainer {
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
}

.layoutContainer__header {
    height: 50px;
}

.layoutContainer__navigation {
    margin-top: 100px;
    height: 50px;
}

.layoutContainer__mainContent {
    min-height: 600px;
    width: 100%;
}

.Content img {
    margin-top: 5px;
    width: 100%;
    height: 100%;   
}

.whatsappButton {
    position: fixed !important;
    bottom: 20px;
    right: 10px;
    background-color: var(--whatsapp-icon-bg-color) !important;
    color: var(--whatsapp-icon-text-color) !important;
}

.phoneButton {
    position: fixed !important;
    bottom: 100px;
    right: 10px;
    background-color: var(--call-icon-bg-color) !important;
    color: var(--call-icon-text-color) !important;
}

@media (min-width: 1024px) {
    .layoutContainer__mainContent {
        margin-top: 180px;
        flex: 1;
        width: 100%;
    }
}

@media (min-width: 1050px) {
    .layoutContainer__mainContent {
        margin-top: 150px;
        flex: 1;
        width: 100%;
    }
}
