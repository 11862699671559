.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.info_container {
    width: 70%;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 30px 30px 30px 100px;
}

.info_container__image img {
    width: 100%;
    max-height: 350px;
}

.info_container__title {
    padding: 30px 0px 10px 0px;
    color: var(--main-description-text-color);
    font-weight: bold;
}

.info_container__card {
    margin-top: 10px;
    padding: 30px;
    border-radius: 10px;
    background-image: linear-gradient(to right, #F3F8FF, #F0F4FF);
    color: var(--button-text-color);
    text-align: left;
    font-weight: 500;
}

.info_container__description {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
}

.info_container__details {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    line-height: 1.5;
}

.info_container__details__content {
    width: 50%;
    /* padding: 5px; */
}

.info_container__details__price {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    width: 40%;
}

.form_container {
    width: 30%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    padding: 30px 100px 10px 30px;
}

.info_container__details__action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info_container__details__action_description {
    font-weight: bold;
    padding: 20px;
    color: var(--main-description-text-color);
}

@media (max-width: 400px) {

    .info_container, .form_container {
        padding: 10px;
    }

    .info_container__description, .info_container__details__content {
        padding: 20px;
        text-align: left;
    }
}


@media (max-width: 800px) {
    .info_container, .form_container, 
    .info_container__details__content, .info_container__details__price  {
        width: 100%;
        padding: 10px;
    }
    

    .info_container__description {
        padding: 10px;
        text-align: left;
    }
}


@media (min-width: 801px) and (max-width: 1024px) {
    .info_container__details__content  {
        width: 50%;
    }

    .info_container__details__price  {
        width: 50%;
    }
    .info_container {
        width: 60%;
    }
    
    .form_container {
        width: 40%;
    }
}