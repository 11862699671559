* {
  box-sizing: border-box;
}

:root {
  --primary-color: #ffffff;
  --primary-color-text: black;

  --secondary-color: black;
  --secondary-color-text: #ffffff;

  --header-font-color: #0C1125;
  --header-icon-color: #ffffff;
  --header-background-color: #0C1125;

  --nav-background-color: #0C1125;
  --nav-text-color: #0C1125;
  --nav-hover-color: #0C1125;
  --nav-hover-text-color: #ffffff;
  --nav-shadow-color: #0C1125;

  --form-input-border-color: #0C1125;

  --button-background-color: #84cc16;
  --button-font-color: #1A2357;

  --secondary-background-color: #1A2357;
  --secondary-background-color-text: #ffffff;
  --tertiary-background-color: #eee;
  --tertiary-background-color-text: rgb(156, 150, 150);

  --secondary-background-color-variant: #d4d2f8;
  --secondary-background-color-variant-text: #474748;
  
  --circle-background-color: #1A2357;
  --circle-header-color: #9291c6;
  --circle-text-color: #fff;

  --whatsapp-icon-bg-color: #25b125;
  --whatsapp-icon-text-color: #fff;
  --call-icon-bg-color: #1A2357;
  --call-icon-text-color: #fff;

  --form-title-color: #1A2357;

  --footer-background-color: #0C1125;
  --footer-color-text: #ffffff;
  --footer-icon-color: #ffffff;

  --main-description-text-color: #0C1125;
  
  --pricing-card-background-color: #acdbf1;
  --pricing-card-text-color: #0C1125;

  --pricing-circle-background-color: #1A2357;

  --danger-color: #d61f1fee;
}

.font_xlg {
  font-size: 40px;
}

.font_lg {
  font-size: 25px;
}

.font_llg {
  font-size: 20px;
}

.font_md {
  font-size: 16px;
}

.font_sm {
  font-size: 14px;
}

.font_xs {
  font-size: 12px;
}

body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: 'Courier New', monospace;
}

.container {
  height: 100%;
  text-align: center;
}

.footer_icons {
  color: var(--footer-icon-color);
}

.icons:hover {
  opacity: 0.5;
}

.slick-prev {
  top: 50px !important;
  left: 150px !important;
  z-index: 9999;
}

.slick-next {
  top: 50px !important;
  right: 150px !important;
}

.slick-track {
  width: 50%;
}

.button_variant_1 {
  border: 2px solid transparent;
  border-radius: 5px;
  background-color: var(--button-background-color);
  color: var(--button-font-color);
  padding: 14px;
  font-weight: bold;
  cursor: pointer;
  display: block;
  /* box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%); */
}


.button_variant_2 {
  border: 2px solid transparent;
  /* border-radius: 4em; */
  background-color: var(--button-background-color);
  color: var(--button-font-color);
  padding: 16px;
  font-weight: bold;
  cursor: pointer;
  display: block;
  /* box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%); */
}

.button_variant_3 {
  border: 2px solid transparent;
    border-radius: 5px;
    width: 150px;
    background-color: var(--button-background-color);
    color: var(--button-font-color);
    padding: 10px;
    font-weight: bold;
    cursor: pointer;
    display: block;
    /* box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%); */
}

button:hover {
  background-color: #65a30d; /* hover:bg-lime-600 */
}


.slick-dots li button {
  background-color: var(--secondary-background-color-variant) !important;
  border-radius: 10px;
}

.slick-dots li button:before {
  color:  var(--secondary-background-color-variant) !important;
}

.slick-dots li.slick-active button:before {
  background-color: var(--secondary-background-color-variant-text) !important;
  border-radius: 10px;
}


.MuiAlert-filledInfo {
  /* background-color: var(--secondary-background-color-variant) !important;  
  color: var(--secondary-background-color-variant-text) !important;  */
  font-weight: bold;
}

textarea:focus, input:focus {
  outline: none;
}

.spinner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.ReactModal__Overlay, .ReactModal__Overlay--after-open {
  z-index: 1000;
}


.ReactModal__Content--after-open, .ReactModal__Content {
  /* border-radius: 10px !important; */
  inset: 80px 350px !important;
  max-height: 620px;
  /* background-color: var(--tertiary-background-color) !important; */
}

.css-cpgvjg-MuiSnackbar-root {
  align-items: center !important;
  justify-content: center !important;
  bottom: 20px !important;
  left: 0px !important;
  right: 0px !important;
}

.p-datepicker-calendar {
  margin-left: -1px;
}

.p-button {
  background-color: var(--button-background-color);
  padding: 12px;
  border-color: var(--button-background-color);
}

.p-button-label {
  color: var(--circle-background-color);
  font-weight: bold;
}

.p-dialog-header {
  color: var(--circle-background-color);
}

.close_icon {
  color: var(--main-description-text-color);
}

@media (max-width: 1025px) {
  .slick-prev {
    left: 30px !important;
  }
  
  .slick-next {
    right: 30px !important;
  }
  
  .ReactModal__Content--after-open, .ReactModal__Content {
    inset: 100px 100px !important;
  }
}

@media (max-width: 450px) {
  .ReactModal__Content--after-open, .ReactModal__Content {
    inset: 20px !important;
  }

  .mobileScreens {
    display: none;
  }
}