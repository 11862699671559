.navigation_container {
    background-color: var(--nav-background-color);
    background-color: var(--nav-hover-text-color);
    min-width: 100%;
    margin-top: -4px;
    display: flex;
    justify-content: space-around;
    box-shadow: 2px 2px 2px 2px var(--nav-shadow-color);
    padding: 4px;
}

.navigation_container__list {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
}

.navigation_container__item a {
    display: inline-flex;
    padding: 12px;
    text-decoration: none;
    color: var(--nav-text-color);
    cursor: pointer;
    /* font-weight: bold; */
}

.navigation_container__item a:hover {
    background-color: var(--nav-hover-color);
    color: var(--nav-hover-text-color);
}


.active {
    background-color: var(--nav-hover-color);
    color: var(--nav-hover-text-color) !important;
}

@media (max-width: 800px) {
    .navigation_container__list {
        display: block;
        margin: 0px;
    }
}

