.header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: var(--header-background-color);
    align-items: center;
}

.logo_menu_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    flex-grow: 1;
}

.header_container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}

.header_content {
    padding: 20px;
    color: var(--secondary-color-text);
    font-weight: 500;
    display: flex;
    flex-direction: column;
    line-height: 1.5
}

.header_button {
    border: 2px solid transparent;
    border-radius: 4em;
    background-color: var(--button-background-color);
    color: var(--button-font-color);
    padding: 20px 20px;
    font-weight: bolder;
    cursor: pointer;
}

.header_button:hover {
    opacity: 0.7;
}

.hamburger {
    display: none;
}


@media (min-width: 1024px) {
    .header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
    }
}

/* Responsive styles */
@media (max-width: 820px) {
    .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        margin-right: 20px;
    }

    .header_container {
        display: none;
    }
}
