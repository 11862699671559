.footerContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: center;
    position: relative; 
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--footer-background-color);
    color: var(--footer-color-text);
    padding: 20px;
}

.footerContainer__list {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
}

.footerContainer__item {
    display: inline-block;
    padding: 10px;
    cursor: pointer;
}

.footerContainer__item a {
  color: var(--white-color);
  text-decoration: none;
}

.footerContainer__item a:hover {
    opacity: 0.7;
}