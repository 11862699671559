.circle {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    width: 256px;
    height: 256px;
    border-radius: 50%;
    border: 8px solid var(--pricing-circle-background-color);
    align-items: center;
    background-image: none !important;
    margin-top: 10px;
}

.normal {
    text-align: center;
}

.circle_head {
    padding: 5px;
    color: var(--main-description-text-color);
    font-weight: bold;
}

.circle_text {
    padding: 5px;
    color: var(--main-description-text-color);
}

span {
    font-size: 13px;
    color: var(--pricing-circle-background-color);
}

.circle_price {
    font-weight: bold;
    color: var(--main-description-text-color);
}


.circle_price, .circle_price_unit {
    padding: 5px;
}

.circle:hover {
    border: 8px solid var(--pricing-circle-background-color);
    /* background-color: linear-gradient(to right, var(--pricing-card-background-color), #c6ea94); */
    background-color: #c6ea94;
    /* background-image: inherit; */
}

.circle:hover > div:first-of-type {
    display: none;
}

.circle:hover > div:last-of-type {
    display: block;
}

.overlay {
    display: none;
    text-align: center;
    font-weight: bold;
}

.circle_link {
    margin-top: 20px;
    text-decoration: underline;
}

.tc {
    padding-top: 5px;
    color: var(--main-description-text-color);
}