.circle {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    width: 256px;
    height: 256px;
    border-radius: 50%;
    background-color: var(--circle-background-color);
    color: var(--circle-text-color);
    margin-top: 10px;
}

.circle_head {
    padding: 3px;
    color: var(--circle-header-color);
    font-weight: bold;
}

.circle_price {
    font-weight: bold;
}


.circle_price, .circle_price_unit {
    padding: 3px;
    color: var(--button-background-color);
}

.circle_terms_condition {
    margin-top: 5px;
}