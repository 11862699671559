.reviewContainer {
    padding: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80%;
    max-width: 600px; 
    margin: 10px auto;
    color: var(--circle-background-color);
}

.carouselContent {
    text-align: justify;
    line-height: 1.5;
}

.carouselSubContent {
    margin-top: 20px;
    text-align: justify;
    font-weight: bold;
    font-size: 1rem;
}

.testimonysection__title {
    color: var(--circle-background-color);
    line-height: 1.5;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: justify;
    padding: 0;
    margin: 20px 0px 20px 0px;
}

.rating_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to right, #F3F8FF, #F0F4FF);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .rating_score {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    color: var(--circle-background-color);
  }

  .rating_score_text {
    font-size: 16px;
    color: var(--circle-background-color);
  }
  
  .google_logo {
    height: 32px;
    margin-right: 2px;
  }
  
  .review_button {
    background-color: var(--button-background-color);
    color: var(--button-font-color);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
  }
  
  .review_button:hover {
    background-color: #65a30d;
  }
  