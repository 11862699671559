.stats_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  .stats_item {
    text-align: center;
    padding: 10px;
  }

  .stats_item_counter {
    color: #7ac142;
    font-size: 2em;
    margin: 0;
    font-weight: 500;
  }
  
  .stats_item p {
    color: #666666;
    font-size: 1em;
    margin: 5px 0 0 0;
  }
  
  .vertical_line {
    border-left: 2px solid #e5e5e5;
    height: 100px;
    margin: 0 10px;
  }

  @media (max-width: 600px) {
    .stats_container {
        flex-direction: column;
    }

    .vertical_line {
        display: none;
    }
}
  