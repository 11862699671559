/* YourComponent.module.css */
.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to right, #1A2357, #383d58); /* from-blue-900 to transparent */
    min-height: 550px;
}

.services__title {
    color: var(--circle-background-color);
    line-height: 1.5;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 24px 0px;
}

.backgroundImage {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center;
    opacity: 0.5;
}

.content {
    z-index: 10;
    text-align: left;
    color: var(--tertiary-background-color);
    padding: 10px;
}

.title {
    margin-bottom: 2rem;
}

.description {
    font-size: 18px;
    margin-bottom: 2rem;
    font-weight: 500;
    color: var(--tertiary-background-color);
    text-align: left;
}

.link {
    display: inline-flex;
    align-items: center;
    background-color: var(--button-background-color);
    color: var(--button-font-color);
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    transition: background-color 0.2s;
    border-radius: 5px;
}

.link:hover {
    background-color: #65a30d; /* hover:bg-lime-600 */
}

.arrow {
    margin-left: 0.3rem;
    font-weight: 600;
}
